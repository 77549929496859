<template>
  <div class="flex justify-center">
    <div v-if="mediaStore.mediaTypeId && mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.bm_preview_images_id">
      <img
          :src="showCustomPreviewImage()"
          alt=""
          class="p-16 md:p-0" style="max-width: 500px;max-height: 500px"
          v-if="showCustomPreviewImage()">
    </div>

    <img :src="mediaStore.media.preview_image" alt=""
         class="p-16 md:p-0" style="max-width: 500px;max-height: 500px"
         v-if="showDefaultPreviewImage()">
    <!--    <div v-if="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.preview">-->
    <!--      <img :src="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.preview" alt=""-->
    <!--           class="p-16 md:p-0" style="max-width: 500px;max-height: 500px"-->
    <!--           v-if="['image/jpeg', 'image/png'].includes(mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.mime_type)">-->

    <!--      <video controls :src="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.preview" alt=""-->
    <!--             class="p-16 md:p-0" style="max-width: 500px;max-height: 500px"-->
    <!--             v-if="['video/mp4', 'video/mov'].includes(mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.mime_type)"></video>-->
    <!--    </div>-->
    <!--    <div-->
    <!--        v-if="!mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.preview ||-->
    <!--          !['image/jpeg', 'image/png', 'video/mp4', 'video/mov'].includes(mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.mime_type)">-->
    <!--      <img :src="mediaStore.media.preview_image" alt="" class="p-16 md:p-0"-->
    <!--           style="max-width: 500px;max-height: 500px">-->
    <!--    </div>-->
  </div>
</template>

<script>
import {useMediaStore} from "../../../../store/media/media-store";

export default {
  name: 'PreviewMedia',
  components: {},
  setup() {
    const mediaStore = useMediaStore()

    const showDefaultPreviewImage = () => {
      return !mediaStore.mediaTypeId ||
          !mediaStore.media['preview_images']['id_' + mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.bm_preview_images_id];
    }

    const showCustomPreviewImage = () => {
      return mediaStore.media['preview_images']['id_' + mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.bm_preview_images_id];
    }

    return {mediaStore, showDefaultPreviewImage, showCustomPreviewImage}
  }
}
</script>

<style scoped>
</style>
