<template>
  <div v-click-outside="closeBox" class="relative w-full" v-if="$props.belongsTo">
    <div v-if="userStore.user.media_types_allowed.includes($props.belongsTo)">
      <button
          class="bg-black text-white rounded border border-black
      font-thin transition duration-500 hover:bg-fmu-fontcolor
      focus:outline-none disabled:opacity-30 font-sans float-right flex break-normal ml-2 px-5"
          style="padding-top: 3px; padding-bottom: 3px" @click="boxIsHidden = !boxIsHidden">
        <span class="mt-1">Direkt teilen</span>
        <img :src="require('@/assets/icons/teilen.svg')" alt="Teilen" class="h-8">
      </button>
    </div>
    <el-tooltip class="item" effect="dark" content="Nicht im Paket enthalten" placement="right" v-else>
      <button
          class="bg-black text-white rounded border border-black
      font-thin transition duration-500 hover:bg-fmu-fontcolor
      focus:outline-none disabled:opacity-30 opacity-30 font-sans float-right ml-2 px-5 flex"
          style="padding-top: 3px; padding-bottom: 3px">
        <span class="mt-1">Direkt teilen</span>
        <img :src="require('@/assets/icons/teilen.svg')" alt="Teilen" class="h-8">
      </button>
    </el-tooltip>
    <div
        class="absolute right-0 bg-white z-10 top-16 shadow p-5 w-64 h-72 border border-fmu-secondary text-sm font-sans font-thin"
        :class="{hidden: boxIsHidden}" v-if="$props.platform === 'app_preset'">
      <div class="relative h-full">
        <div class="text-center pb-1 flex justify-center">
          <!--          <img :src="require(`@/assets/icons/${$props.platform}.svg`)" alt="Teilen" class="h-5 pr-2">-->
          <p>In die Kampagnensteuerung übertragen</p>
        </div>
        <hr>
        <p class="text-center pt-4 pb-4 text-xs"></p>
        <div class="flex justify-center">
          <img :src="require('@/assets/icons/smart.svg')" alt="Teilen" class="h-20">
        </div>
        <div @click="mediaStore.appProcess()" v-if="mediaStore.campaignId">
          <fmu-button class="text-xs py-1 absolute bottom-0 w-full">Im smart.KIS öffnen</fmu-button>
        </div>
      </div>
    </div>
    <div
        class="absolute right-0 bg-white z-10 top-16 shadow p-5 w-64 h-72 border border-fmu-secondary text-sm font-sans font-thin"
        :class="{hidden: boxIsHidden}" v-if="$props.platform !== 'app_preset' && userStore.user.media_types_allowed.includes($props.platform)" >
      <div class="relative h-full">
        <div class="text-center pb-1 flex justify-center">
          <img :src="require(`@/assets/icons/${$props.platform}.svg`) ?? null" alt="Teilen" class="h-5 pr-2">
          <p v-if="$props.platform === 'facebook'" class="text-xs">Diesen Content direkt auf Deiner Facebook Seite
            hochladen!</p>
          <p v-if="$props.platform === 'instagram'" class="text-xs">Diesen Content direkt auf Deiner Instagram Seite
            hochladen!</p>
          <p v-if="$props.platform === 'news_blog'" class="text-xs">Diesen Content direkt auf Deinem Shop-Newsblog
            hochladen!</p>
          <p v-if="$props.platform === 'mobileApp'" class="text-xs">Diesen Content direkt auf Deinem smart.+App Newsblog
            hochladen!</p>
        </div>
        <hr>
        <p class="text-center pt-4 pb-2 text-xs">Veröffentliche das Design via
          Hutter & Unger Content-Hub direkt auf
          <span v-if="$props.platform === 'facebook'">Deiner Facebook Seite</span>
          <span v-if="$props.platform === 'instagram'">Deiner Instagram Seite</span>
          <span v-if="$props.platform === 'news_blog'">Deiner Shop-Newsblog Seite</span>
          <span v-if="$props.platform === 'mobileApp'">Deiner App</span>
          ...</p>
        <div class="flex justify-center">
          <img :src="require('@/assets/icons/teilen-box.svg')" alt="Teilen" class="h-20">
        </div>
        <div @click="share">
          <fmu-button class="text-xs py-1 absolute bottom-0 w-full">Mit Content-Hub verbinden</fmu-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue"
import FmuButton from "@/components/theme/button/FmuButton";
import vClickOutside from 'click-outside-vue3'
import {ElTooltip} from "element-plus"
import {useMediaStore} from "@/store/media/media-store";
import {baseUrl} from "@/configurations/configurations";
import Cookies from "js-cookie";
import {useUserStore} from "@/store/user/user-store";

export default {
  name: 'ShareButton',
  props: ['platform', 'mediaTypeId', 'belongsTo', 'v3CampaignId'],
  components: {FmuButton, ElTooltip},
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup(props) {
    const boxIsHidden = ref(true)
    const mediaStore = useMediaStore();
    const userStore = useUserStore()

    const share = () => {
      window.open(`${baseUrl}fmu/content-hub/content/${props.mediaTypeId}/${mediaStore.media.id}/${Cookies.get('token')}`, "_blank");
    }

    const closeBox = () => {
      boxIsHidden.value = true;
    }

    return {boxIsHidden, closeBox, share, userStore, mediaStore}
  }
}
</script>

<style scoped>
</style>
