<template>
  <div class="flex justify-center">
    <div class="grid grid-cols-1 md:grid-cols-2 md:mt-32 md:mb-12 fmu-container">
      <div class="mx-10">
        <preview-media></preview-media>

        <!--    DESKTOP-->
        <div class="md:mt-10 justify-center hidden md:flex" v-if="mediaStore.mediaTypeId">
          <div v-if="typeof mediaStore.mediaTypeId !== 'string'">
            <fmu-button button-type="primary"
                        @button-click="mediaStore.download()"
                        v-if="mediaTypeAllowed()">
              Download
            </fmu-button>
            <el-tooltip class="item" effect="dark" content="nicht im Paket enthalten" placement="left"
                        v-if="mediaTypeNotAllowed()">
              <div>
                <fmu-button button-type="primary" disabled="true">
                  <img :src="require('@/assets/icons/lock.svg')" alt="im Paket nicht enthalten"
                       class="h-3.5 mt-1 px-0.5 float-left">
                  <span class="float-left text-white">Download</span>
                </fmu-button>
              </div>
            </el-tooltip>
          </div>
          <div>
            <share-button
                :platform="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.belongs_to ?? mediaStore.mediaTypeId"
                :media-type-id="mediaStore.mediaTypeId"
                :belongs-to="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.belongs_to ?? null"
                v-if="shareChannels.includes(mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.belongs_to) || typeof mediaStore.mediaTypeId !== 'string'"></share-button>
            <share-button :platform="'app_preset'" :media-type-id="'app_preset'"
                          :belongs-to="'app_preset'" v-if="typeof mediaStore.mediaTypeId === 'string'"></share-button>
          </div>
        </div>
        <!--    END DESKTOP-->

      </div>
      <div>
        <div class="px-10 md:px-0">
          <h1 class="font-condensed text-5xl md:text-headline text-black font-semibold uppercase"
              style="line-height: 1">
            {{ mediaStore.media.title }}</h1>
          <p class="text-lg pt-3 " v-if="mediaStore.media.description">
            {{ mediaStore.media.description }}
          </p>
        </div>
        <div class="md:flex px-10 md:px-0 mt-10 md:mt-0">

          <!--      CHANNELS-->
          <div v-for="(media_type, index) in subStore.subOptions" :key="media_type.id" class="mt-4 md:mt-10">
            <el-tooltip class="item" effect="dark" :content="media_type.media_type" placement="top"
                        v-if="mediaStore.media[`media_type_${media_type.id}`] && mediaStore.media[`media_type_${media_type.id}`]?.content_type !== 'word_files'">
              <div class="bg-black p-2 hover:bg-bm-primary flex md:block rounded"
                   :class="{'bg-bm-primary': media_type.id === mediaStore.mediaTypeId, 'mx-1': index != 1, 'mr-1': index == 1}"
                   @click="mediaStore.setMediaTypeId(media_type.id)">
                <img
                    :src="mediaStore.media[`media_type_${media_type.id}`]?.icon ?? require('@/assets/icons/content/content_fallback.svg')"
                    alt="" class="cursor-pointer h-6">
                <p class="md:hidden text-white font-bold pl-3">{{ media_type.media_type }}</p>
              </div>
            </el-tooltip>
          </div>

          <!--      CAMPAIGNS-->
          <div v-for="campaign in mediaStore.media.v3_campaign_ids" :key="campaign.id" class="md:mt-10">
            <el-tooltip class="item" effect="dark" :content="`App ${campaign.type}: ${campaign.name_internal}`"
                        placement="top" v-if="mediaTypeAllowed('app_preset')">
              <div class="bg-black mx-1 p-2 hover:bg-bm-primary flex md:block rounded"
                   :class="{'bg-bm-primary': campaign.type === mediaStore.mediaTypeId}"
                   @click="mediaStore.setMediaTypeId(campaign.type, campaign.id)">
                <img :src="getAppPresetIcon(campaign.type)" alt="" class="cursor-pointer h-6">
                <p class="md:hidden text-white font-bold pl-3">App {{ campaign.type }}: {{ campaign.name_internal }}</p>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="pt-8 px-10 md:px-0" v-if="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.text">
          <h3 class="font-bold text-2xl font-condensed text-black">Hier können Sie den Text für Ihren Post kopieren:</h3>
          <p class="pt-3" v-html="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.text"></p>
        </div>
        <!--    MOBILE-->
        <div class="md:mt-10 flex justify-center md:hidden mt-10" v-if="mediaStore.mediaTypeId">
          <div v-if="typeof mediaStore.mediaTypeId !== 'string'">
            <fmu-button button-type="primary"
                        @button-click="mediaStore.download()"
                        v-if="mediaTypeAllowed()">
              Download
            </fmu-button>
            <el-tooltip class="item" effect="dark" content="nicht im Paket enthalten" placement="left"
                        v-if="mediaTypeNotAllowed()">
              <div>
                <fmu-button button-type="primary" disabled="true">
                  <img :src="require('@/assets/icons/lock.svg')" alt="im Paket nicht enthalten"
                       class="h-3.5 mt-1 px-0.5 float-left">
                  <span class="float-left text-white">Download</span>
                </fmu-button>
              </div>
            </el-tooltip>
          </div>
          <div>
            <share-button
                :platform="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.belongs_to ?? mediaStore.mediaTypeId"
                :media-type-id="mediaStore.mediaTypeId"
                :belongs-to="mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.belongs_to ?? null"
                v-if="shareChannels.includes(mediaStore.media[`media_type_${mediaStore.mediaTypeId}`]?.belongs_to) || typeof mediaStore.mediaTypeId !== 'string'"></share-button>
            <share-button :platform="'app_preset'" :media-type-id="'app_preset'"
                          :belongs-to="'app_preset'" v-if="typeof mediaStore.mediaTypeId === 'string'"></share-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FmuButton from "@/components/theme/button/FmuButton";
import {ElTooltip} from "element-plus"
import {useMediaStore} from "@/store/media/media-store";
import {useSubStore} from "@/store/subs/sub-store";
import {onMounted} from "vue"
import {useUserStore} from "@/store/user/user-store";
import ShareButton from "@/components/theme/button/ShareButton";
import PreviewMedia from "./components/PreviewMedia";

export default {
  name: 'MediaContent',
  components: {PreviewMedia, ShareButton, FmuButton, ElTooltip},
  emits: ['button-click'],
  setup() {
    const mediaStore = useMediaStore();
    const subStore = useSubStore();
    const userStore = useUserStore();
    const shareChannels = ['news_blog', 'instagram', 'facebook', 'mobileApp'];

    onMounted(() => {
      subStore.getSubOptions();
    })

    /**
     * if type is app
     * => check if type is in subscription and if user has app in smartkis
     * else only check if type is in subscription
     * @returns {*}
     */
    const mediaTypeAllowed = (mediaTypeId) => {
      let allowed;
      mediaTypeId = mediaTypeId ?? mediaStore.mediaTypeId?.toString();
      if (mediaTypeId === 'app_preset') {
        // allowed = userStore.user.has_app && mediaStore.media.v3_campaign_ids && mediaStore.media.v3_campaign_ids.length > 0;
        allowed = mediaStore.media.v3_campaign_ids && mediaStore.media.v3_campaign_ids.length > 0;
      } else {
        allowed = userStore.user.media_types_allowed.includes(mediaTypeId);
      }
      return allowed
    }

    /**
     * opposite of mediaTypeAllowed
     * @returns {boolean}
     */
    const mediaTypeNotAllowed = () => {
      let notAllowed;
      let mediaTypeId = mediaStore.mediaTypeId?.toString();

      if (mediaTypeId === 'app_preset') {
        notAllowed = !userStore.user.has_app || !mediaStore.media.v3_campaign_ids;
      } else {
        notAllowed = !userStore.user.media_types_allowed.includes(mediaTypeId);
      }
      return notAllowed
    }

    const getAppPresetIcon = (type) => {
      switch (type) {
        case 'Gutschein':
          return require('@/assets/icons/content/content_app-gutschein.svg');
        case 'Einladung':
          return require('@/assets/icons/content/content_app-einladung.svg');
        case 'Bonusscheck':
          return require('@/assets/icons/content/content_app-bonusscheck.svg');
      }
    }

    return {
      getAppPresetIcon,
      shareChannels,
      mediaTypeAllowed,
      mediaTypeNotAllowed,
      userStore,
      subStore,
      mediaStore,
    }
  }
}
</script>

<style scoped>
</style>
