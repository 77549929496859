<template>
<!--  <media-header></media-header>-->
  <media-content></media-content>
  <small class="flex justify-center text-center p-10 text-xxs">
    Dieser Content ist urheberrechtlich geschützt und darf nur für Ihr abonniertes Unternehmen verwendet werden!
    <br> Missbrauch wird geahndet!
  </small>
</template>

<script>
import MediaContent from "./overview/MediaContent";
import {onMounted} from "vue"
import {useMediaStore} from "@/store/media/media-store";
import {useRoute} from 'vue-router'

export default {
  name: 'Product',
  components: {
    MediaContent
  },
  setup() {
    const mediaStore = useMediaStore();
    const route = useRoute()

    onMounted(async () => {
      if (route.params.slug && mediaStore.media.length === 0) {
        await mediaStore.getMedia(route.params.slug)
      }
    })
  }
}
</script>
<style>
</style>
